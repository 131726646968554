import React from 'react';

import Slide1 from 'src/slides/desktop/de/chapter_4/sub_1/slide1';
import Slide2 from 'src/slides/desktop/de/chapter_4/sub_1/slide2';

import WrapperMobile from 'src/slides/mobile/de/chapter_4/sub_1/wrapperMobile';

import { isMobile } from 'react-device-detect';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Bolschewistische Offensive | Schlacht bei Warschau" lang="de" description="Über die Leiche von Weiß-Polen zur weltweiten Revolution" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Bolschewistische Offensive | Schlacht bei Warschau" lang="de" description="Über die Leiche von Weiß-Polen zur weltweiten Revolution" />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
