import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_1/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "slide-4-1-2"}}) {
      body
    }
    file(relativePath: { eq: "chapter_4/sub_1/mobile/4-1-2-bg.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    feliksDzierzynski:  mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "czerwona-ofensywa-modal"}, selector: {eq: "feliksDzierzynski"}}) {
      body
    },
    julianMarchlewski: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "czerwona-ofensywa-modal"}, selector: {eq: "julianMarchlewski"}}) {
      body
    },
    feliksKon: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "czerwona-ofensywa-modal"}, selector: {eq: "feliksKon"}}) {
      body
    },
    iwanSkworcowStiepanow: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "czerwona-ofensywa-modal"}, selector: {eq: "iwanSkworcowStiepanow"}}) {
      body
    },
    hoverDot: mdx(
      frontmatter: {
        language: { eq: "DE" }
        title: { eq: "czerwona-ofensywa-modal" }
      }
    ) {
      exports {
        hoverInfo {
          positionLeft
          positionTop
          insideText
          identifier
        }
      }
    },
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
